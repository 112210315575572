body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
main {
  overflow-x: hidden; /*or overflow-x:scroll */
  display: flex;
}

h1 {
  margin: 0;
}
h2 {
  margin: 0;
  font-weight: normal;
}
h3 {
  margin: 0;
}


a {
  color: inherit;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}